<template>
  <a-card :bordered="false">
    <a-form :form="form" style="width: 70%; margin: 0 auto;">
      <a-row :gutter="[16,16]">
        <a-col :span="10">
          <a-form-item label="名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="10" >
          <a-form-item label="HS编号" >
            <a-input v-decorator="['hs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="7">
          <a-form-item label="增值税率" >
            <a-input-number
              :max="0.99"
              :min="0.00"
              :step="0.01"
              style="width: 100%"
              v-decorator="['value_added_tax_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item label="消费税率" >
            <a-input-number
              :max="0.99"
              :min="0.00"
              :step="0.01"
              style="width: 100%"
              v-decorator="['consumption_tax_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="6" >
          <a-form-item label="关税税率" >
            <a-input-number
              :max="0.99"
              :min="0.00"
              :step="0.01"
              style="width: 100%"
              v-decorator="['tariff_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="6">
          <a-form-item label="单位" >
            <a-input v-decorator="['unit', {rules: [{required: true, message: '必填项，请填写信息'}, {max:4, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="14" >
          <a-form-item label="原产地" >
            <a-input v-decorator="['original_place', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="10">
          <a-form-item label="英文名" >
            <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="10" >
          <a-form-item label="排序" >
            <a-input placeholder="请输入数字，数字小展示在前面" v-decorator="['serial', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="2" >
          <a-form-item label="保质期开关" >
            <a-switch
              @change="handleLifeChange"
              v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}, {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="10" >
          <a-form-item label="保质期" v-show="is_show">
            <a-input v-decorator="['shelf_life', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="保质期单位" v-show="is_show">
            <a-select
              v-decorator="[
                'shelf_life_unit',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.SelfLifeUnit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="20" >
          <a-form-item label="备注" >
            <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6" :offset="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="8">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { bonded_goods_list, bonded_goods_update } from '@/api/bonded_goods'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      is_show: false,
      form: this.$form.createForm(this),
      id: 0
    }
  },
  beforeCreate () {
    this.form = this.$form.createForm(this)
  },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      bonded_goods_list({ id: this.$route.params.id })
        .then(({ data }) => {
          const list = data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
    // let arr = ['id', 'name', 'en_name', 'code', 'original_place', 'hs_code', 'serial', 'value_added_tax_rate', 'consumption_tax_rate', 'tariff_rate', 'remark', 'unit', 'open_self_life', 'shelf_life', 'shelf_life_unit']
    // arr.forEach(v => this.form.getFieldDecorator(v))
  },
  methods: {
    handleLifeChange (value) {
      console.log(`selected ${value}`)
      this.is_show = !!value
    },
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        bonded_goods_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      const formData = pick(data, ['id', 'name', 'en_name', 'code', 'original_place', 'hs_code', 'serial', 'value_added_tax_rate', 'consumption_tax_rate', 'tariff_rate', 'remark', 'unit', 'open_self_life', 'shelf_life', 'shelf_life_unit'])
      console.log('formData', formData)
      formData.shelf_life_unit = formData.shelf_life_unit + ''
      this.is_show = formData.open_self_life
      this.id = formData.id
      form.setFieldsValue(formData)
    }
  }
}
</script>
